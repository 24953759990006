//
// Reset Styles
@import "~normalize.css/normalize.css";

html {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

img {
    image-rendering: -moz-optimizeQuality;
    /* Firefox */
    image-rendering: -o-optimizeQuality;
    /* Opera */
    image-rendering: -webkit-optimize-contrast;
    /* Webkit (non-standard naming) */
    image-rendering: optimizeQuality;
    -ms-interpolation-mode: bicubic;
    /* Internetexplorer */
    border: 0;
    /* removes border around images in older Internetexplorer */
}

select {
    width: 100%;
}

/* Remove standard design of browsers */
button {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    padding: 0;
    border: 0;
    line-height: 1;
    transition: background 0.35s linear;
}

p {
    //line-height: 1.45rem
}

textarea {
    height: auto;
    min-height: 50px;
}

input {
    &[type='text'],
    &[type='password'],
    &[type='email'] {
        min-width: 150px;
    }
}

select {
    background: transparent;
    padding: 3px;
}

body {
    margin: 0;
    padding: 0;
    font-family: "PT Sans", sans-serif;
}

body {
    margin: 0;
    background-color: #1c1d2a;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Averia Serif Libre", serif;
}

.mobile-nav-toggle {
    margin: 0 0 0 -10px;
    @media (min-width: 768px) {
        display: none;
    }
}